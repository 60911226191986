<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Update Profile
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->

    <b-card
      class="mb-3 p-2 user__profile"
    >
      <b-table-simple
        borderless
        responsive
      >
        <b-tbody>
          <b-tr>
            <b-th>
              Profile Image
            </b-th>
            <b-td />
          </b-tr>
          <b-tr>
            <b-th
              class="image__block"
            >
              <b-img
                class="mb-1 mb-sm-0 user__avatar"
                height="100"
                :src="fileSrc || user.image"
              />
            </b-th>

            <b-td
              class="upload__buttons"
            >
              <b-form-file
                ref="fileInput"
                v-model="file"
                type="file"
                accept="image/*"
                style="display: none"
                @input="setImage"
              />
              <b-button
                variant="primary"
                class="mr-50"
                @click="$refs.fileInput.$el.childNodes[0].click()"
              >
                Upload
              </b-button>
              <b-button
                variant="outline-primary"
                @click="file = null; fileSrc = null"
              >
                Reset
              </b-button>
              <br>
              <small>Allowed JPEG, GIF or PNG. Max size of 800kB</small>
            </b-td>
          </b-tr>

          <b-tr>
            <b-th>
              Signature
            </b-th>
            <b-td />
          </b-tr>
          <b-tr>
            <b-th
              class="image__block"
            >
              <b-img
                class="mb-1 mb-sm-0 user__avatar"
                height="100"
                :src="signatureSrc || user.signature"
              />
            </b-th>

            <b-td
              class="upload__buttons"
            >
              <b-form-file
                ref="signatureInput"
                v-model="signature"
                type="file"
                style="display: none"
                accept="image/png, image/jpeg"
                @input="setSignature"
              />
              <b-button
                variant="primary"
                class="mr-50"
                @click="$refs.signatureInput.$el.childNodes[0].click()"
              >
                Upload
              </b-button>
              <b-button
                variant="outline-primary"
                @click="signature = null; signatureSrc = null"
              >
                Reset
              </b-button>
              <br>
              <small>Allowed PNG and JPEG. Max size of 800kB</small>
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Name
            </b-th>
            <b-td>{{ user.name }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Email
            </b-th>
            <b-td>{{ user.email }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Position
            </b-th>
            <b-td>{{ user.position }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Department
            </b-th>
            <b-td>
              <div class="">
                <p
                  v-for="(department, key) in user.department"
                  :key="key"
                  class="mb-0"
                >
                  {{ department.name }}
                </p>
              </div>
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Roles
            </b-th>
            <b-td>
              <!-- <strong
                v-for="(role, key) in user.roles"
                :key="key"
              >
                {{ role.role }}<span v-if="key + 1 != user.roles.length">, </span>
              </strong> -->
              <b-button
                v-for="(role, key) in user.roles"
                :key="key"
                :title="role.role"
                variant="outline-danger"
                class="small-spacing-button"
              >
                {{ role.role }}
              </b-button>
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Status
            </b-th>
            <b-td
              class="text-capitalize"
            >
              <span :class="resolveStatus(user.status)">{{ user.status }}</span>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <b-row>
        <b-col cols="6">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="button"
            variant="success"
            :disabled="!fileSrc && !signatureSrc"
            @click="submitImage"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            <span class="align-middle">Save Changes</span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="button"
            variant="flat-primary"
            :to="{ name: 'directory-my-profile' }"
          >
            Cancel
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BButton, BCol, BTableSimple, BTbody, BTr, BTh, BTd, BImg, BFormFile,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BImg,
    BFormFile,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      user: store.state.auth.userData,
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/frontend/logo-nirvana-memorial-garden.86fe2ff5.svg'),
      file: null,
      fileSrc: '',
      signature: null,
      signatureSrc: '',
    }
  },
  methods: {
    setImage() {
      if (this.file && this.file.size <= 819200) {
        const reader = new FileReader()
        reader.readAsDataURL(this.file)
        reader.onload = () => {
          this.fileSrc = reader.result
        }
      }
    },
    setSignature() {
      if (this.signature && this.signature.size <= 819200) {
        const reader = new FileReader()
        reader.readAsDataURL(this.signature)
        reader.onload = () => {
          this.signatureSrc = reader.result
        }
      }
    },
    submitImage() {
      const formData = new FormData()

      formData.append('image', this.fileSrc ? this.fileSrc : this.user.image)
      formData.append('signature', this.signatureSrc ? this.signatureSrc : this.user.signature)

      this.$http.patch(`directory/users/${this.user._id}/update/profile`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message ?? '',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
          this.$store.commit('auth/update_image', { userImage: response.data.userImage, signature: response.data.signature })
          this.$router.push({ name: 'directory-my-profile' })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    resolveStatus(status) {
      if (status === 'active') return 'text-success'
      if (status === 'inactive') return 'text-danger'
      return 'text-primary'
    },
  },
}
</script>
